<template>
    <div>
      <CCard class="wrapper" v-if="!isMobile">
        <img v-if="deviceImages.length > 0 && deviceImages[0].url" :src="deviceImages[0].url" style="max-height: 700px;" />
        <div v-else>Loading...</div>
        <div class="preset">
            <div class="list">
                <select name="number" v-model="selected" @change="select()">
                    <option v-for="(preset, index) in list" :value="preset" :key="index">{{ preset }}</option>
                </select>
            </div>
            <div class="value">
                <p>X축: <span> {{ xLevel }}</span></p> 
            </div>
            <div class="value">
                <p>Y축: <span> {{ yLevel }}</span></p>
            </div>
            <div class="value">
                <p>ZOOM: <span> {{ zoomLevel }}</span></p> 
            </div>
            <div class="save" @click="save()">
                <i class="fa-solid fa-floppy-disk fa-xl"></i>
            </div>
            <div class="reset" @click="reset()">
                <i class="fa-solid fa-recycle fa-xl"></i>
            </div>
        </div>
        <div class="zoom">
            <div class="box" @click="zoomIn()">
                <i class="fa-solid fa-circle-plus fa-2xl"></i>
            </div>
            <div class="box" @click="zoomOut()">
                <i class="fa-solid fa-circle-minus fa-2xl"></i>
            </div>            
            <!--
            <div class="box" @click="zoomLevel++">
                <i class="fa-solid fa-circle-plus fa-2xl"></i>
            </div>
            <div class="box" @click="zoomLevel--">
                <i class="fa-solid fa-circle-minus fa-2xl"></i>
            </div>
            -->
        </div>
        <div class="direction">
            <div class="box" @click="shoot()">
                <i class="fa-solid fa-camera fa-2xl"></i>
            </div>
            <div class="box" @click="left()">
                <i class="fa-solid fa-caret-left fa-2xl"></i>
            </div>
            <div class="box" @click="right()">
                <i class="fa-solid fa-caret-right fa-2xl"></i>
            </div>
            <div class="box" @click="up()">
                <i class="fa-solid fa-caret-up fa-2xl"></i>
            </div>
            <div class="box" @click="down()">
                <i class="fa-solid fa-caret-down fa-2xl"></i>
            </div>            
            <!--
            <div class="box" @click="xLevel--">
                <i class="fa-solid fa-caret-left fa-2xl"></i>
            </div>
            <div class="box" @click="xLevel++">
                <i class="fa-solid fa-caret-right fa-2xl"></i>
            </div>
            <div class="box" @click="yLevel++">
                <i class="fa-solid fa-caret-up fa-2xl"></i>
            </div>
            <div class="box" @click="yLevel--">
                <i class="fa-solid fa-caret-down fa-2xl"></i>
            </div>
            -->
        </div>
      </CCard>

      <div class="mobile" v-else>
        <CCard>
            <img v-if="deviceImages.length > 0 && deviceImages[0].url" :src="deviceImages[0].url" style="max-height: 700px;" />
            <div v-else>Loading...</div>
            <CCardBody>
                <div class="direction">
                    <div class="box" @click="left()">
                        <i class="fa-solid fa-caret-left fa-2xl"></i>
                    </div>
                    <div class="box" @click="right()">
                        <i class="fa-solid fa-caret-right fa-2xl"></i>
                    </div>
                    <div class="box" @click="up()">
                        <i class="fa-solid fa-caret-up fa-2xl"></i>
                    </div>
                    <div class="box" @click="down()">
                        <i class="fa-solid fa-caret-down fa-2xl"></i>
                    </div>
                </div>
                <div class="zoomShoot">
                    <div class="box" @click="zoomIn()">
                        <i class="fa-solid fa-circle-plus fa-2xl"></i>
                    </div>
                    <div class="box" @click="zoomOut()">
                        <i class="fa-solid fa-circle-minus fa-2xl"></i>
                    </div>
                    <div class="box" @click="shoot()">
                        <i class="fa-solid fa-camera fa-2xl"></i>
                    </div>
                    <div class="box">
                        <!-- <i class="fa-solid fa-circle-plus fa-2xl"></i> -->
                    </div>
                </div>
                <CCard>
                    <CCardBody>
                        <h2>Preset 설정</h2>
                        <div class="preset">
                            <div class="valueWrapper">
                                <div class="value">
                                    <div>X축 : {{ xLevel }}</div>
                                    <div>Y축 : {{ yLevel }}</div>
                                    <div>ZOOM : {{ zoomLevel }}</div>
                                </div>
                                <div class="list">
                                    <select v-model="selected" @change="select()" name="number">
                                        <option v-for="(preset, index) in list" :value="preset" :key="index">{{ preset }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="btnWrapper">
                                <div class="box">
                                    <i class="fa-regular fa-circle-check fa-2xl"></i>
                                </div>
                                <div class="box" @click="save()">
                                    <i class="fa-solid fa-floppy-disk fa-2xl"></i>
                                </div>
                                <div class="box" @click="reset()">
                                    <i class="fa-solid fa-recycle fa-2xl"></i>
                                </div>
                            </div>
                        </div>
                    </CCardBody>    
                </CCard>
            </CCardBody>
        </CCard>
      </div>

      <Confirm
        ref="confirmDialog"
        title="확인"
        @hide="hideModal"
        color="warning"
      />
    </div>
</template>

<script>
import loopback from '@/services/loopback';
import moment from 'moment';
import Device from '@/views/srds/device/Device'
  
export default {
    name: 'CameraPTZ',
    extends: Device,
    components: {
    },
props: {
    device_guid: {
        type: String
    },
    device: {
        type: Object
    },
    isMobile: {
        type: Boolean
    }
},
data() {
    return {
        list: [1,2,3,4,5,6],
        deviceImages: [],
        storage: 'flowPhotos',
        zoomLevel: 0, // 0부터 10까지
        xLevel: 0,
        yLevel: 0,
        selected: 1,
    }
},
watch: {
    device: {
        deep: true,
        handler(newVal, oldVal) {
            this.getADeviceImage()
        }
    }
},
methods: {
    shoot() {
        console.log("촬영버튼")
        //this.takePicture()
    },
    left() {
        console.log("카메라 왼쪽 1회")
        this.xLevel++
        this.moveCameraPTZ();
        //this.xLevel = 0;
    },
    right() {
        console.log("카메라 오른쪽 1회")
        this.xLevel--
        //this.moveCameraPTZ();
        this.xLevel = 0;
    },
    up() {
        console.log("카메라 위쪽 1회")
        this.yLevel++
        //this.moveCameraPTZ();
        this.yLevel = 0;
    },
    down() {
        console.log("카메라 아래쪽 1회")
        this.yLevel--
        //this.moveCameraPTZ();
        this.yLevel = 0;
    },  
    save() {
        // Preset 설정 저장
        console.log(" Preset 설정 저장 ")
    },
    reset() {
        // Preset 초기화 
        console.log(" Preset 초기화 / 삭제 ")
    },
    zoomIn() {
        console.log(" 줌 확대 1회")
        this.zoomLevel++
    },
    zoomOut() {
        console.log(" 줌 축소 1회")
        this.zoomLevel--
    },
    select() {
        console.log("선택된 List : ", this.selected)
        console.log("List 선택시 저장된 preset값 적용")
    },
    takePicture() {
      var data = {
        command: 'exec',
        device_guid: this.device_guid,
        params: [{
          cmd: 'shot',
          data: "takepic.jpg",
          opt: {
            "-w": 640,
            "-h": 480,
            "-q": 5,
            "-o": "takepic.jpg",
            "-ev": -10,
            "-ex": "backlight",
            "-mm": "backlit",
            "-awb": "greyworld"
          }
        }]
      }
      // console.log('Camera::takePicture params:', data)
      this.$store.dispatch('rest/method', {model:'devices',method:'Command',data:data})
        .then(res => {
          console.log('Camera::takePicture res: ', res)
        })
        .catch(err => {
          console.log('Camera::takePicture error: ', err.toString())
          var message = err.toString();
          if(message === "Error: Already running command: run after a minutes"){
            message = "이미 처리중인 작업이 있습니다. 잠시만 기다려주세요.";
          }
          this.$refs.notifyDialog.show(message.toString())
        })
    },   
    moveCameraPTZ() {
      var data = {
        command: 'exec',
        device_guid: this.device_guid,
        params: [{
          cmd: 'move',
          data: {
                speed : {
                    x: this.xLevel,
                    y: this.yLevel,
                    z: this.zoomLevel
                },
                abs: "True",
                cnt: 5,
                timeout: 300
            }
        }]
      }
      // console.log('Camera::takePicture params:', data)
      this.$store.dispatch('rest/method', {model:'devices',method:'Command',data:data})
        .then(res => {
          console.log('Camera::takePicture res: ', res)
        })
        .catch(err => {
          console.log('Camera::takePicture error: ', err.toString())
          var message = err.toString();
          if(message === "Error: Already running command: run after a minutes"){
            message = "이미 처리중인 작업이 있습니다. 잠시만 기다려주세요.";
          }
          this.$refs.notifyDialog.show(message.toString())
        })
    },       
    getADeviceImage() {
        var self = this;
        this.deviceImages = [];
        var download_url = '{0}/{1}/{2}/download'.format(loopback.defaults.baseURL, this.storage, this.device_guid);
        var filter = {
            where: {
                and: [
                    {
                        device_guid: self.device_guid
                    },  
                ]
            },
            order: 'created_at desc',
            limit: 1,
        }

            self.$store.dispatch('rest/find', {model:'photos', filter: filter})
                .then(function(response) {
                    var pushImage = function(info) {
                    self.deviceImages.push({
                            url: download_url + '/' + info.file_name,
                            // created_at: moment(info.created_at).format('YYYY년 MM월 DD일 HH시 mm분 ss초')
                            created_at: moment(info.created_at).format('YYYY년 MM월 DD일'),
                            created_at_thumb: moment(info.created_at).format('YYYY-MM-DD'),
                            created_at_time: moment(info.created_at).format('HH시 mm분 ss초')
                    })
                }
                for (var i = 0; i < response.length; i++) {
                var info = response[i]
                if (_.isUndefined(info.storage)) continue;
                    pushImage(info);
                }
                if (self.deviceImages.length == 0) {
                    var network_type = self.device.network_type;
                    download_url = 'img/tech9_dev.png'; 
                        switch (network_type.toLowerCase()) {
                        case 'nb-iot': case 'cat.m1': download_url = 'img/tech9_plug.png'; break; 
                        case 'lora': download_url = 'img/tech9_lora.jpg'; break;
                    }
                self.deviceImages.push({
                    url: download_url
                })
                }
            })
    },
    pictureDialog() {
      var message = this.device.name + " 의 사진촬영을 진행하시겠습니까?"
      this.$refs.confirmDialog.show(message, '사진 촬영', undefined, 'pic');      
    }, 
    getPresetList() {
        // 검색을 위해서는 device_guid 필요
        console.log(this.list)
    }
    }
}
</script>
  
<style lang="scss" scoped>
.wrapper {
    .preset {
        background-color: whitesmoke;
        display: flex;
        position: absolute;
        top: 1.4%;
        left: 1%;
        border: 1px solid gray;
        border-radius: 10px;
        padding: 1%;
        .list {
            height: 40px;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            select {
                width: 60px;
                font-weight: bold;
                text-align: center;
            }
        }
        .value {
            width: 88px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            font-size: 14px;
            font-weight: bold;
            span {
                font-size: 18px;
                &:last-child {
                    margin-right: 12px;
                }
            }
        }
        .save, .reset {
            width: 40px;
            height: 40px;
            background-color: rgba(217,217,217, 0.75);
            border-radius: 10px;
            position: relative;
            cursor: pointer;
            .fa-floppy-disk, .fa-recycle {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .save {
            margin-right: 16px;
        }
    }
    .zoom {
        display: flex;
        position: absolute;
        top: 1.4%;
        right: 1%;
        .box {
          border-radius: 50%;
          width: 64px;
          height: 64px;
          background-color: rgba(217,217,217, 0.75);
          position: relative;
          cursor: pointer;
          &:first-child {
            margin-right: 16px;
          }
          .fa-circle-plus, .fa-circle-minus {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
    }
    .direction {
        display: flex;
        position: absolute;
        bottom: 1.4%;
        right: 1%;
        .box {
            width: 64px;
            height: 64px;
            background-color: rgba(217,217,217, 0.75);
            border-radius: 10px;
            position: relative;
            cursor: pointer;
            margin-right: 16px;
            &:last-child {
                margin-right: 0;
            }
            .fa-camera {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .fa-caret-left, .fa-caret-right, .fa-caret-up, .fa-caret-down {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
.mobile {
    position: relative;
    .direction {
        display: flex;
        .box {
            width: 60px;
            height: 60px;
            background-color: rgba(217,217,217, 0.75);
            border-radius: 10px;
            position: relative;
            cursor: pointer;
            margin-right: 26px;
            &:last-child {
                margin-right: 0;
            }
            .fa-caret-left, .fa-caret-right, .fa-caret-up, .fa-caret-down {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .zoomShoot {
        display: flex;
        margin: 14px 0;
        .box {
            width: 60px;
            height: 60px;
            background-color: rgba(217,217,217, 0.75);
            border-radius: 10px;
            position: relative;
            cursor: pointer;
            margin-right: 26px;
            &:last-child {
                margin-right: 0;
            }
            .fa-camera, .fa-circle-plus, .fa-circle-minus {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .preset {
        text-align: center;
        .btnWrapper {
            display: flex;
            margin-bottom: 14px;
            justify-content: flex-end;
            .box {
                width: 48px;
                height: 48px;
                background-color: rgba(217,217,217, 0.75);
                border-radius: 10px;
                position: relative;
                cursor: pointer;
                margin-right: 16px;
                .fa-circle-check, .fa-floppy-disk, .fa-recycle {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
        .valueWrapper {
            display: flex;
            border-radius: 8px;
            border: 1px solid gray;
            margin: 14px 0;
            .value {
                width: 50%;
            }
            .list {
                width: 50%;
                position: relative;
                border-left: 1px solid gray;
                display: flex;
                align-items: center;
                justify-content: center;
                select {
                    width: 80%;
                    text-align: center;
                }
            }
        }
    }
}
</style>