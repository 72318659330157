<template>
  <KModal
  :show.sync="showModal"
  :no-close-on-backdrop="true"
  :centered="true"
  title="Create Device Dialog"
  color="info"
  >
    <template #header>
    <h6 class="modal-title text-center">센서 기준 설정</h6>
    </template>

    <div class='modal-body'>
          <CCol>
            <CAlert color="info" style="font-size:16px; font-weight: bold;">기준값 설정</CAlert>
            <div class='modal-body'>
              <form name='thresholdForm'>
                <div class="row">
                    <div class="col-sm-4 col-lg-4">
                      <label>온도(℃)</label>
                      <input type='number' id='set_temp' class='form-control' placeholder='0.0' v-model='standard.temp' :step='.1'>
                    </div>
                    <div class="col-sm-4 col-lg-4">
                      <label>습도(%)</label>
                      <input type='number' id='set_humi' class='form-control' placeholder='0.0' v-model='standard.humi' :step='.1'>
                    </div>
                    <div class="col-sm-4 col-lg-4">
                      <label>CO2(ppm)</label>
                      <input type='number' id='set_co2' class='form-control' placeholder='0.0' v-model='standard.co2' :step='.1'>
                    </div>
                </div>
                <div class="search-margin"></div>
              </form>
            </div>

            <div class="table">
              <div class="tableHeader">
                <div class="headerItem">측정일시</div>
                <div class="headerItem">온도</div>
                <div class="headerItem">습도</div>
                <div class="headerItem">CO2</div>
                <div class="headerItem">
                  <CButton class="btn" color="info" @click="setInclineData">조회</CButton>
                </div>
              </div>
              <div v-for="(item, index) in inclineDataSet" :key="index" class="tableContent">
                <transition name="fade">
                  <div v-if="item.isActive" class="contentWrapper">
                    <div class="content" style="font-size: 12px; line-height: 15px;">{{ item.created_at }}</div>
                    <div class="content">{{ item.temp }}</div>
                    <div class="content">{{ item.humi }}</div>
                    <div class="content">{{ item.co2 }}</div>
                    <div class="content"><CButton class="btn" color="success" @click="setStandardValue(item)">{{ toggle }}</CButton></div>
                    <!-- <div class="content">
                      <label class="c-switch c-switch-success">
                        <input type="checkbox" class="c-switch-input" >
                        <span class="c-switch-slider"></span>
                      </label>
                    </div> -->
                  </div>
                </transition>
              </div>
            </div>
          </CCol>

          <CCol>
            <CAlert color="primary" style="font-size:16px; font-weight: bold;">임계치 설정</CAlert>
            <div>
            <CRow>
              <CCol col="12" xl="5">
                <label>센서</label>
              </CCol>
              <CCol col="12" xl="7">
                <label>기준값<strong class='text-danger'>*</strong></label>
              </CCol>
            </CRow>
            <CRow id="row">
              <CCol col="12" xl="5">
                <CBadge color="success" id="title">온도 (℃)</CBadge>
              </CCol>
              <CCol>
                <input type="number" style="font-size:14px;" class='form-control' min=0 max=360 v-model='sensor_threshold.temp' :step="0.1" />
              </CCol>
            </CRow>
            <CRow id="row">
              <CCol col="12" xl="5">
                <CBadge color="info" id="title">습도 (%)</CBadge>
              </CCol>
              <CCol>
                <input type="number" style="font-size:14px;" class='form-control' min=0 max=360 v-model='sensor_threshold.humi' :step="0.1" />
              </CCol>
            </CRow>
            <CRow id="row">
              <CCol col="12" xl="5">
                <CBadge color="secondary" id="title">CO<sub>2</sub> (ppm)</CBadge>
              </CCol>
              <CCol>
                <input type="number" style="font-size:14px;" class='form-control' min=0 max=360 v-model='sensor_threshold.co2' :step="0.1" />
              </CCol>
            </CRow>
            </div>
          </CCol>
    </div>

    <template #footer>
      <CButton @click="register()" color="info">저장</CButton>
      <CButton @click="cancel()" color="light">취소</CButton>
    </template>

  <Notify ref="notifyDialog"/>

  </KModal>
</template>
  
<script>
import Incline from '@/views/srds/device/Incline'

export default {
    name: 'SWFMThreshold',
    extends: Incline,
    data() {
      return {
        weight: {
          g: 0.0,
          gw: 0.0,
          gd: 0.0
        },
        sensor_threshold:{
          temp: 0.0,
          humi: 0.0,
          co2: 0.0
        },
        standard: {
          temp: 0.0,
          humi: 0.0,
          co2: 0.0
        },           
      }
    },
    watch: {
      device: {
        deep: true,
        handler(val) {
          this.sensor_threshold.temp = this.device.standard.t_threshold;
          this.sensor_threshold.humi = this.device.standard.h_threshold;
          this.sensor_threshold.co2 = this.device.standard.c_threshold;            
          this.standard = this.device.standard
        }
      }        
    },   
    methods: {
      /** 테이블 작성 */
      setInclineData() {
        let inclineData = []
        for(let data of this.deviceIncline) {
          let incline = {}
          incline.created_at = this.dateFormatter(data.created_at)
          incline.isActive = true
          for(let item of data.data) {
            if(item.type === 6) {
              switch (item.id) {
                case 1:
                  incline.temp = item.value
                  break;
                case 2:
                  incline.humi = item.value
                  break;
                default:
                  break;
              }
            }
            else if(item.type === 16)
              incline.co2 = item.value
          }
          inclineData.push(incline)
          incline = {}
        }
        this.inclineDataSet = inclineData
      },
      /** 선택버튼클릭 후 데이터 반영 */
      setStandardValue(item) {
        this.standard.temp = item.temp
        this.standard.humi = item.humi
        this.standard.co2 = item.co2
        
        item.isActive = false
      },   
      register() {
        var where = {
          _id: this.device.guid
        }
        this.standard.t_threshold = this.sensor_threshold.temp;
        this.standard.h_threshold = this.sensor_threshold.humi;
        this.standard.c_threshold = this.sensor_threshold.co2;

        var data = {
          standard: this.standard
        }
        console.log(data)
        this.$store.dispatch('rest/upsertWithWhere', {model:'devices',where:where,data:data})
          .then(res => {
            let msg = {
              name: this.$options.name,
              which: this.which,
              data: res
            }
            this.$emit('update', msg)
            this.showModal = false;
          })      
          .catch(err => {
            console.log('Threshold::register error:'. err.toString())
            this.showModal = false;
          })
      },    
    },
}
</script>
  
<style scoped lang="scss">
.modal-body {
  text-align: center;
  #title {
    font-size: 1.1rem;
  }
  #row {
    margin-bottom: 2%;
  }
}
.table {    
  .tableHeader {
    display: flex;
    height: 40px;
    align-items: center;
    border-bottom: 1px solid gray;
    .btn {
      // background-color: green;
      color: #fff;
    }
    .headerItem {
      width: 25%;
      color: gray;
      line-height: 40px;
      text-align: center;
    }
  }
  .tableContent {
    background-color: #f2f2f2;
    &:hover {
      border-radius: 0px 10px 10px 0px;
      background-color: #fff;
      box-shadow: 0 0 10px rgba(33,33,33,.2);
      border-left: 4px solid #2EB85C;
    }
    .contentWrapper {
      height: 50px;
      display: flex;
      align-items: center;
      text-align: center;
      border-bottom: 1px solid #d9d9d9;
      .content {
        width: 25%;
        line-height: 50px;
      }
      .btn {
        // background-color: #23ADC6;
        color: #fff;
      }
    }
  }
}
</style>