<template>
  <CCard>
      <CCol col="12" xl="12">
        <ul class="nav nav-tabs nav-pills" style="padding-top: 15px;">
          <li class="nav-item">
            <a tab class="nav-link" @click.prevent="setActive('tab3')" :class="{ active: isActive('tab3') }" href='#tab3'>현재 상태</a>
          </li>        
          <li class="nav-item">
            <a tab class="nav-link" @click.prevent="setActive('tab4')" :class="{ active: isActive('tab4') }" href='#tab4'>측정 이력</a>
          </li>        
          <li class="nav-item">
            <a tab class="nav-link" @click.prevent="setActive('tab5')" :class="{ active: isActive('tab5') }" href='#tab5'>PTZ 모드</a>
          </li> 
        </ul>
      </CCol>   
      <div class="tab-content" style="padding: 15px;">
        <div class="tab-pane" id='tab3' :class="{ 'active show': isActive('tab3') }">
            <Device 
              :device_guid="this.$route.params.id" 
              :device.sync="device" 
              @update="onUpdated"
              @reset="onResetSensor"/>
        </div>
        <div class="tab-pane" id='tab4' :class="{ 'active show': isActive('tab4') }">
          <History ref="historyView" :device_guid="this.$route.params.id" :device.sync="device"/>
        </div>
        <div class="tab-pane" id='tab5' :class="{ 'active show': isActive('tab5') }">
          <CameraPTZ 
            :device_guid="this.$route.params.id" 
            :device.sync="device"
            :isMobile="is_mobile"
            @update="onUpdated"
            @reset="onResetSensor"
          />
        </div>
      </div>     
    </CCard>
</template>


<script>
import DeviceManage from '@/views/srds/device/DeviceManage'
import Device from '@/views/swfm/device/DeviceDetail'
import CameraPTZ from '@/views/swfm/device/CameraPTZ'

export default {
  name: 'SWFMDeviceManage',
  extends: DeviceManage,
  components: {
    Device,
    CameraPTZ
  },    
  data() {
    return {
      is_mobile: this.$is_mobile,
    }
  }
}
</script>